<!-- 现烤生产绩效 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  shopProduceListAPI,
  shopProduceListSummaryAPI,
} from "@/api/freshlyBaked/report/groupQuery"; //单据查询
import business from "@/utils/mixin/business"; //业务字典
export default {
  name: "groupQuery",
  mixins: [business],
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "现烤单据查询",
        rowKey: "shopId",
        // getTabsColumns: ({ tabColumnType } = {}) => ({
        //   columns: this.detailedSummaryFields(
        //     tabColumnType,
        //     this.options.search.find((x) => x.filter === "isSummary")?.model
        //   ),
        // }),
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/现烤编号/现烤名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "roomNos", label: "现烤编号" },
              { filter: "roomNames", label: "现烤名称" },
            ],
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "receipt" ||
                this.options?.tabColumnType == "materialOrder" ||
                this.options?.tabColumnType == "purchase",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "freshlyBakedListRoom",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "freshlyBakedWorkerList",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "purchase",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择供应商",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "supplier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCustomer",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "clientSaleOrder" ||
                this.options?.tabColumnType == "clientSale",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择客户",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "customer" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            label: "报损原因",
            seniorSearch: true,
            hide: () => this.options?.tabColumnType == "scrap",
            type: "local",
            model: "",
            filter: "scrapTypeIds",
            option: {
              multiple: true,
              data: this.shopScrapTypList,
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
            },
          },
          this.$select({
            key: "listSimpleQuery",
            option: {
              filter: "deptIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "clientSaleOrder" ||
                this.options?.tabColumnType == "clientSale",
              option: {
                multiple: true,
                // buttons: [
                //   {
                //     type: 'more',
                //     option: {
                //       title: '选择部门',
                //       width: 1250,
                //       type: 'TreeAndTable',
                //       formData: this.$dialog({ key: 'supplier' })
                //     }
                //   }
                // ]
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        // tabColumnType: "receipt",
        tabsColumns: [
          {
            title: "现烤师生产汇总",
            type: "produce",
            defaultBody: { isSummary: true },
            getSummaryApi: shopProduceListSummaryAPI,
            getListApi: shopProduceListAPI,
            uuid: "144063b5-cdf6-45c4-89f2-69f92209bd8f",
            exportOption: {
              fastExportUrl:
                "/api/system/freshly/baked/bill/produce/report/listBillProduceExport",
              exportName: "现烤师生产汇总",
            },
            columns: [
              {
                prop: "workerName",
                label: "现烤师",
                minWidth: 155,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "现烤生产明细查询",
            type: "produce",
            defaultBody: { isSummary: false },
            getSummaryApi: shopProduceListSummaryAPI,
            getListApi: shopProduceListAPI,
            uuid: "5bf5eaeb-e874-e18e-326e-c3ec094bedf4",
            exportOption: {
              fastExportUrl:
                "/api/system/freshly/baked/bill/produce/report/listBillProduceExport",
              exportName: "现烤生产查询",
            },
            columns: [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "roomNo",
                label: "现烤编号",
                minWidth: 150,
              },
              {
                prop: "roomName",
                label: "现烤名称",
                minWidth: 150,
              },
              {
                prop: "workerName",
                label: "现烤师",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney"],
          },
        ],
      },
      shopScrapTypList: [],
    };
  },
  async created() {
    await this.business("shop_scrap_type");
    this.options.search[10].option.data = this.busiDicts?.shop_scrap_type;
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          const billId = row.billId;
          this.$router.push({
            name: "FreshlyBakedProduceDetail",
            query: {
              billId: billId,
              type: "Update",
            },
          });
          break;
        default:
          break;
      }
    },
    detailedSummaryFields(type, isBol) {
      //isBol  汇总true  明细false
      //type    哪个页面
      let table = [];
      switch (type) {
        case "receipt":
          if (isBol) {
            table = [
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "roomNo",
                label: "现烤编号",
                minWidth: 150,
              },
              {
                prop: "roomName",
                label: "现烤名称",
                minWidth: 150,
              },
              {
                prop: "workerName",
                label: "现烤师",
                minWidth: 150,
              },
              {
                prop: "produceOrderName",
                label: "生产班次",
                minWidth: 150,
              },
              {
                prop: "orderStoreName",
                label: "订货仓库",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产工厂",
                minWidth: 150,
              },
              {
                prop: "arrivalDate",
                label: "到货日期",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        case "produce":
          if (isBol) {
            table = [
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          } else {
            table = [
              {
                prop: "billNo",
                label: "单据编号",
                type: "link",
                click: "update",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "roomNo",
                label: "现烤编号",
                minWidth: 150,
              },
              {
                prop: "roomName",
                label: "现烤名称",
                minWidth: 150,
              },
              {
                prop: "workerName",
                label: "现烤师",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
            ];
          }
          break;
        default:
          break;
      }
      console.log("table", table);
      return table;
    },
  },
};
</script>
